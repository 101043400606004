<template>
  <div id="worldMap"></div>
</template>
<script>
import $ from 'jquery'
import { debuglog } from 'util'
let graphData
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },

  data() {
    return {
      newData: null,
    }
  },
  methods: {
    initVectorMap() {
      window.$('#worldMap').vectorMap({
        map: 'world_mill_en',
        backgroundColor: 'transparent',
        zoomOnScroll: false,
        regionStyle: {
          initial: {
            fill: '#e4e4e4',
            'fill-opacity': 0.9,
            stroke: 'none',
            'stroke-width': 0,
            'stroke-opacity': 0,
          },
        },

        series: {
          regions: [
            {
              values: this.data,
              scale: ['#AAAAAA', '#444444'],
              normalizeFunction: 'polynomial',
            },
          ],
        },

        onRegionTipShow: function (event, label, code) {
          if (graphData[code] >= 0) {
            label.html(
              '<b>' +
                label.html() +
                '</b></br>' +
                '<p>' +
                graphData[code] +
                '</p>'
            )
          }
        },
      })
    },
  },

  getData() {
    return this.data
  },

  async mounted() {
    graphData = this.data
    window.$ = window.jQuery = $
    await import('jvectormap-next')
    await import('./world_map.js')
    this.initVectorMap()
  },
}
</script>
<style></style>
